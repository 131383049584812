import React, { useContext, useState, useEffect } from "react";

import { PinMapContext } from "./PinMapProvider";

import { safeParseJSON } from "./utils/helpers";
import { getPinImage } from "./utils/api";

const PinDetailsSidebar = () => {
  const { openPin, setOpenPin } = useContext(PinMapContext);

  let parsedDescription = safeParseJSON(openPin.properties.description);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (!openPin) return;

    if (openPin.properties.is_public_life_photo && openPin.properties.id) {
      getPinImage(openPin.properties.id).then((imageUrl) => setImageUrl(imageUrl));
    } else{
      setImageUrl(null);
    }
  }, [openPin]);

  return (
    <div
      id="pin-details-sidebar-container"
      className="right-sidebar-container sidebar-container"
    >
      <div className="close-btn" onClick={() => setOpenPin(false)} />
      <h1 className="mb-3 me-5">{openPin.properties.title}</h1>

      <p className="small mb-0">Location:</p>
      <p>{openPin.properties.location_name}</p>

      <p className="small mb-0">Created at:</p>
      <p>{new Date(openPin.properties.created_at).toLocaleDateString()}</p>

      {openPin.properties.needs_confirmation && (
        <p className="fw-bold text-primary my-2">Please click on the link in the confirmation email we sent you, in order for your photo to be visible.</p>
      )}

      {imageUrl && (
        <div
          key={imageUrl}
          className="public-life-photo w-100 p-2"
        >
          <img src={imageUrl} className="w-100" />
        </div>
      )}

      {parsedDescription
        .filter((item) => item.answers && item.answers.length > 0)
        .map((item, index) => (
          <div key={index} style={{ marginBottom: "1rem" }}>
            <p className="small">{item.question}</p>
            <ul className="">
              {item.answers.map((answer, i) => (
                <li className="" key={i}>{answer}</li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export default PinDetailsSidebar;
