import canvasConfetti from 'canvas-confetti';

export const getRandomItems = (arr, numItems) => {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numItems);
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // Assuming array elements are primitive types
  a.sort();
  b.sort();

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};

export const safeParseJSON = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};

export const resizeImage = (file, maxWidth, maxHeight, callback) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = document.createElement('img');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        callback(blob);
      }, file.type, 1);
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);
}

export const fireConfetti = () => {
  var count = 200;
  var defaults = {
    origin: { y: 0.7 },
    colors: ['#42B9D3', '#51D07A', '#1C3E35', '#F3ECDD']
  };

  const firePart = (particleRatio, opts) => {
    canvasConfetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio)
    }));
  }

  firePart(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  firePart(0.2, {
    spread: 60,
  });
  firePart(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  firePart(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  firePart(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

export const formatSlug = (text) => {
  return text
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}