import React, { useContext, useEffect, useState, useRef } from "react";

import { PinMapContext } from "./PinMapProvider";

import { filterOptions } from "./utils/filterOptions";
import { formatSlug } from "./utils/helpers";

const FilterSidebar = () => {
  const {
    mode,
    openPin,
    pinFilter,
    setPinFilter,
    countryFilter,
    setCountryFilter,
    showPublicLifeImages,
    setShowPublicLifeImages,
  } = useContext(PinMapContext);

  const [collapsed, setCollapsed] = useState(true);

  const handleFilterClick = (option) => {
    let text = option.text;
    if (text === "all") {
      setPinFilter("");
    } else if (text === "public-life-images") {
      setShowPublicLifeImages((showPublicLifeImages) => !showPublicLifeImages);
    } else {
      pinFilter === text ? setPinFilter("") : setPinFilter(text);
    }
  };

  return (
    <div
      id="filter-sidebar-container"
      className={
        mode === "add" || openPin
          ? "left-sidebar-container floating-sidebar-container d-none"
          : "left-sidebar-container floating-sidebar-container"
      }
    >
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <p className="fw-bold mb-0">Filters and search</p>

          <p
            className="cursor-pointer x-large mb-0"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? "+" : "–"}
          </p>
        </div>

        {countryFilter && (
          <div className="my-3">
            <p className="mb-1 text-primary">
              Currently showing pins in{" "}
              <span className="fw-bold">
                {formatSlug(countryFilter)}
              </span>
            </p>
            <p onClick={() => setCountryFilter(null)} className="my-0 small cursor-pointer fw-bold"> Clear filter</p>
          </div>
        )}
      </div>

      <div
        className={
          collapsed ? "filters-content mt-3 d-none" : "filters-content mt-3"
        }
      >
        <div id="geocoder-container" className="mb-4"></div>

        <div id="filters-container">
          <li
            key="all pins"
            data-text="all"
            onClick={() => handleFilterClick({ text: "all" })}
            className={pinFilter.length === 0 ? "selected" : ""}
          >
            Show me all Favorite Places
          </li>
          <li
            key="public life images"
            id="public-life-images"
            data-text="public-life-images"
            onClick={() => handleFilterClick({ text: "public-life-images" })}
            className={showPublicLifeImages ? "selected" : ""}
          >
            Show Images of Public Life
          </li>

          <p className="mt-4 mb-2 fw-bold">Show me places...</p>
          <ul id="filter-container" className="list-group list-group-flush">
            {filterOptions.map((option) => {
              return (
                <li
                  key={option.text}
                  data-answer={option.answer}
                  data-text={option.text}
                  onClick={() => handleFilterClick(option)}
                  className={pinFilter.includes(option.text) ? "selected" : ""}
                >
                  <span>{option.text}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
