import React, { useContext, useState, useEffect } from "react";

import { PinMapContext } from "./PinMapProvider";

const ModeSelector = () => {
  const { mode, setMode } = useContext(PinMapContext);

 
  return (
    <div
      id="mode-selector-container"
      className="d-flex justify-content-center align-items-center"
    >
      <div className={mode === "explore" ? "mode-selector-btn selected" : "mode-selector-btn"} onClick={() => setMode('explore')}>
        Explore Map
      </div>

      <div className={mode === "add" ? "mode-selector-btn selected" : "mode-selector-btn"} onClick={() => setMode('add')}>
        Add Pin
      </div>
    </div>
  );
};

export default ModeSelector;
