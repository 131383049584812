import React, { useContext, useEffect, useState, useRef } from "react";

import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { featureCollection, polygon, point } from "@turf/helpers";
import { bbox as turfBbox } from "@turf/bbox";
import { union as turfUnion } from "@turf/union";

import { PinMapContext } from "./PinMapProvider";
import { filterOptions } from "./utils/filterOptions";
import { arraysEqual } from "./utils/helpers";
import { smartFlyTo, reverseGeocode } from "./utils/mapHelpers";

const mapboxAccessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

const Map = () => {
  const {
    map,
    mode,
    setMap,
    pinData,
    newPin,
    setNewPin,
    openPin,
    setOpenPin,
    countryFilter,
    pinFilter,
    showPublicLifeImages,
    setVisiblePins,
    containerWidth,
  } = useContext(PinMapContext);

  const mapContainerRef = useRef(null);
  const [] = useState(0);
  const [lng, setLng] = useState(26.4186);
  const [lat, setLat] = useState(38.1278);
  const [zoom, setZoom] = useState(1.66);
  const [currentStyle, setCurrentStyle] = useState(
    "gehl-developer/clxnah6c500jw01pc6npd1xl9"
  );

  const [clickedPoint, setClickedPoint] = useState(null);

  const [lastVisibleIds, setLastVisibleIds] = useState([]);

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;
    const tempMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: `mapbox://styles/${currentStyle}`,
      center: [lng, lat],
      zoom: zoom,
    });

    tempMap.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    tempMap.dragRotate.disable();
    tempMap.touchZoomRotate.disableRotation();

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      marker: false,
      placeholder: "Search for a place",
    });
    geocoder.setFlyTo({ speed: 1.5 });
    document
      .getElementById("geocoder-container")
      .appendChild(geocoder.onAdd(tempMap));

    tempMap.on("moveend", () => {
      setLng(parseFloat(tempMap.getCenter().lng.toFixed(4)));
      setLat(parseFloat(tempMap.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(tempMap.getZoom().toFixed(2)));
    });

    tempMap.on("load", () => {
      setMap(tempMap);
    });

    tempMap.on("click", (e) => {
      setClickedPoint(e);
    });

    return () => {
      tempMap.remove();
    };
  }, [currentStyle]);

  useEffect(() => {
    if (map) addSourcesAndLayers();
  }, [map]);

  useEffect(() => {
    if (!map) return;

    setTimeout(() => {
      updateVisiblePins();
    }, 100);
  }, [map, zoom, lat, lng, pinData]);

  useEffect(() => {
    if (!map || !clickedPoint) return;

    if (mode === "explore") {
      const bbox = [
        [clickedPoint.point.x - 10, clickedPoint.point.y - 10],
        [clickedPoint.point.x + 10, clickedPoint.point.y + 10],
      ];

      const features = map.queryRenderedFeatures(bbox, {
        layers: ["pins"],
      });

      if (features.length > 0) {
        setOpenPin(features[0]);
      }
    } else {
      setNewPin({
        geometry: {
          coordinates: [clickedPoint.lngLat.lng, clickedPoint.lngLat.lat],
        },
      });
    }
  }, [map, clickedPoint]);

  useEffect(() => {
    if (!map || !openPin) return;

    smartFlyTo(
      map,
      containerWidth,
      [openPin.properties.longitude, openPin.properties.latitude],
      Math.max(zoom, 15)
    );
  }, [openPin]);

  useEffect(() => {
    if (newPin && map) {
      smartFlyTo(
        map,
        containerWidth,
        newPin.geometry.coordinates,
        Math.max(zoom, 15)
      );

      map
        .getSource("new-pins")
        .setData(featureCollection([point(newPin.geometry.coordinates)]));
    }
    if (!newPin && map) {
      map.getSource("new-pins").setData(featureCollection([]));
    }
  }, [newPin]);

  useEffect(() => {
    if (!map) return;

    let filter = ["any"];

    if (pinFilter.length > 0) {
      let answers = [];
      filterOptions.forEach((option) => {
        if (pinFilter.includes(option.text)) answers.push(option.answer);
      });
      answers.flat().forEach((a) => {
        filter.push(["in", a, ["get", "answers"]]);
      });
    } else {
      filter.push(["==", ["get", "is_public_life_photo"], false]);
    }

    if (showPublicLifeImages) {
      filter.push(["==", ["get", "is_public_life_photo"], true]);
    }

    map.setFilter("pins", filter);
    setTimeout(() => {
      updateVisiblePins();
    }, 100);
  }, [map, pinFilter, showPublicLifeImages]);

  useEffect(() => {
    if (!map) return;

    if (!countryFilter) {
      map.setFilter("pins", null);

      setTimeout(() => {
        updateVisiblePins();
      }, 100);

    } else {
      const sourceFeatures = map.querySourceFeatures("composite", {
        sourceLayer: "country_boundaries",
      });

      const features = sourceFeatures.filter((f) => {
        return (
          countryFilter.toLowerCase().replace(/-/g, " ") ===
            f.properties.name_en.toLowerCase() ||
          (countryFilter.toLowerCase() === "palestine" &&
            f.properties.name_en === "Palestinian Territories")
        );
      });

      if (features.length > 0) {
        const polygons = [];

        features.forEach((feature) => {
          if (feature.geometry.type === "Polygon")
            polygons.push(polygon(feature.geometry.coordinates));
          if (feature.geometry.type === "MultiPolygon")
            polygons.push(
              ...feature.geometry.coordinates.map((coords) => polygon(coords))
            );
        });

        const fullGeometry = featureCollection(polygons);
        const reducedFullGeometry = featureCollection([
          turfUnion(fullGeometry),
        ]);

        map.setFilter("pins", ["within", reducedFullGeometry]);

        const bbox = turfBbox(reducedFullGeometry);
        map.fitBounds(bbox, { padding: 50, linear: true });
        setTimeout(() => {
          updateVisiblePins();
        }, 100);
      } else {
        console.log("No country found");
      }
    }
  }, [map, countryFilter]);

  useEffect(() => {
    if (map && pinData) {
      const pinsFeatureCollection = featureCollection(
        pinData.map((pin) =>
          point([pin.longitude, pin.latitude], {
            ...pin,
            answers: !pin.description
              ? []
              : pin.description
                  ?.map((d) => {
                    if (d.answers !== undefined) return d.answers;
                  })
                  .flat(),
          })
        )
      );
      map.getSource("pins").setData(pinsFeatureCollection);
    }
  }, [pinData, map]);

  const addSourcesAndLayers = () => {
    map.addSource("pins", {
      type: "geojson",
      data: { type: "FeatureCollection", features: pinData },
    });

    map.addSource("new-pins", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [newPin] },
    });

    map.addLayer({
      id: "pins",
      type: "circle",
      source: "pins",
      paint: {
        "circle-radius": {
          base: 6,
          stops: [
            [12, 6],
            [22, 180],
          ],
        },
        "circle-color": [
          "case",
          [
            "all",
            ["==", ["get", "is_public_life_photo"], true],
            ["!", ["==", ["get", "confirmed_at"], null]],
          ],
          "#51D07A",
          "#1C3E35",
        ],
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
      },
    });

    map.addLayer({
      id: "new-pins",
      type: "circle",
      source: "new-pins",
      paint: {
        "circle-radius": {
          base: 6,
          stops: [
            [12, 6],
            [22, 180],
          ],
        },
        "circle-color": "#0099ff",
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
      },
    });
  };

  const updateVisiblePins = () => {
    if (!map || pinData.length === 0) return;
    const visibleFeatures = map.queryRenderedFeatures({ layers: ["pins"] });
    const visibleIds = visibleFeatures.map((feature) => feature.properties.id);

    if (!arraysEqual(visibleIds, lastVisibleIds)) {
      setVisiblePins(pinData.filter((pin) => visibleIds.includes(pin.id)));
      setLastVisibleIds(visibleIds);
    }
  };

  return (
    <div id="map-container">
      <div id="map" ref={mapContainerRef}></div>
    </div>
  );
};

export default Map;
