export const oldQuestions = [
  {
    question: "title",
    type: "input",
    helpText: "Give your pin a title",
  },
  {
    question: "I chose this place because...",
    helpText: "Select all that apply",
    answers: [
      "It is a good place to relax",
      "It is a good place to meet new people",
      "There are different activities to do here",
      "It feels safe",
      "Of the good air quality",
      "It is a natural stop on the way to where I go",
      "It has a fun atmosphere",
      "It is convenient",
      "It is close to home",
      "It is a place I visit often"
    ],
    type: "multiple-choice"
  },
  {
    question: "I visit this place...",
    helpText: "Select all that apply",
    answers: [
      "By myself",
      "With children",
      "With friends or family",
      "To meet or see new people",
      "With pets",
      "During the day",
      "In the evenings",
      "Mostly at weekends",
      "Only on rare occasions",
      "By bike",
      "By foot",
      "By car",
      "By public transit"
    ],
    type: "multiple-choice"
  },
  {
    question: "This place is attractive to me because...",
    helpText: "Select all that apply",
    answers: [
      "There is nature",
      "There is a great view",
      "There is public art",
      "There is interesting architecture",
      "There are interesting and friendly people",
      "It feels well protected from the weather (rain, wind, sun...)",
      "There are places to sit, relax and spend time",
      "The area feels welcoming",
      "There are amenities like shops, restaurants and cafes",
      "It has safe and inviting areas to play",
      "There are plenty of activity facilities and opportunities for exercise"
    ],
    type: "multiple-choice"
  },
  {
    question: "Tell us about how this place welcomes babies and toddlers",
    helpText: "Select all that apply",
    answers: [
      "I see a lot of babies and toddlers here",
      "I bring my own child or children here",
      "I have friends/family who bring their children here",
      "I never see children in this place",
      "I don\"t know"
    ],
    type: "multiple-choice"
  }
];

export const newQuestions = [
  {
    question: "title",
    type: "input",
    helpText: "Give your pin a title",
    description: "Thank you for showing us this place. We would like to know just a bit more before adding it to the map. Let's start by giving your pin a name. E.g. 'The Ueno Park pond in Tokyo' or 'A great children's playground in Vesterbro, Copenhagen'."
  },
  {
    question: "Select a photo to upload",
    type: "image",
    helpText: "Select a photo to upload",
    description: "Do you have a photo of this place? Adding a photo of your place will help others understand why you like it. Please only upload photos you have taken yourself."
  },
  {
    question: "I chose this place because...",
    helpText: "Select all that apply",
    answers: [
      "It is a good place to relax",
      "It is a good place to meet new people",
      "There are different activities to do here",
      "It feels safe",
      "Of the good air quality",
      "It has a fun atmosphere",
      "It is close to home",
      "It is a place I visit often",
      "None of the above"
    ],
    type: "multiple-choice"
  },
  {
    question: "I visit this place...",
    helpText: "Select all that apply",
    answers: [
      "By myself",
      "With children",
      "With friends or family",
      "During the day",
      "In the evenings",
      "Mostly at weekends",
      "By bike",
      "By foot",
      "By car",
      "By public transit",
      "None of the above"
    ],
    type: "multiple-choice"
  },
  {
    question: "This place is attractive to me because...",
    helpText: "Select all that apply",
    answers: [
      "There is nature",
      "There is public art",
      "There is interesting architecture",
      "There are interesting and friendly people",
      "It feels well protected from the weather (rain, wind, sun...)",
      "There are places to sit, relax and spend time",
      "The area feels welcoming",
      "There are shops, restaurants and cafes",
      "It has safe and inviting areas to play",
      "There are opportunities for exercise and being active",
      "None of the above"
    ],
    type: "multiple-choice"
  },
  {
    question: "Tell us about how this place welcomes babies and toddlers",
    helpText: "Select all that apply",
    answers: [
      "I see a lot of babies and toddlers here",
      "I bring my own child or children here",
      "I have friends/family who bring their children here",
      "I never see children in this place",
      "I don\'t know"
    ],
    type: "multiple-choice"
  }
]