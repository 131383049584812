const testMode = import.meta.env.VITE_TEST_MODE;

const getPins = () => {
  return new Promise((resolve, reject) => {
    fetch(`/pins`)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const createPin = (pinData) => {
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  return new Promise((resolve, reject) => {
    if (testMode === "true") {
      let data = { pin: JSON.stringify({ ...pinData, created_at: new Date() }) };
      if (pinData.image) {
        data.needs_confirmation = true;
      }
      return resolve(data);
    } else {
      const formData = new FormData();
      formData.append("pin[latitude]", pinData.latitude);
      formData.append("pin[longitude]", pinData.longitude);
      formData.append("pin[description]", JSON.stringify(pinData.description));
      formData.append("pin[title]", pinData.title);
      formData.append("pin[user_id]", pinData.user_id);
      formData.append("pin[location_name]", pinData.location_name);

      if (pinData.image) {
        formData.append("pin[image]", pinData.image);
        formData.append("pin[is_public_life_photo]", "true");
        formData.append("pin[email]", pinData.email);
      }

      fetch(`/pins`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": token,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};

const getPinImage = (pinId) => {
  return new Promise((resolve, reject) => {
    fetch(`/pins/${pinId}/image`)
      .then((response) => response.json()) // Convert the response to JSON
      .then((data) => {
        resolve(data.image_url);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export { getPins, createPin, getPinImage };
