import React, { createContext, useState, useRef, useEffect } from 'react';
import { point } from '@turf/helpers';

export const PinMapContext = createContext();

export default function PinMapProvider({props, children }) {

  const [userId] = useState(props.userId ? props.userId : null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [map, setMap] = useState();
  const [pinData, setPinData] = useState([]);

  const [mode, setMode] = useState('explore');

  const [newPin, setNewPin] = useState();
  const [openPin, setOpenPin] = useState(false);
  const [pinFilter, setPinFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState(null);
  const [showPublicLifeImages, setShowPublicLifeImages] = useState(true);

  const [visiblePins, setVisiblePins] = useState([]);

  useEffect(() => {

    if(props.country) {
      let country = props.country;
      setCountryFilter(country);
    }

    if(props.pin && props.pin !== "null") {
      let pinData = JSON.parse(props.pin);
      
      let pin = point([pinData.longitude, pinData.latitude], {
        ...pinData,
        answers: pinData.description,
      });

      setTimeout(() => setOpenPin(pin), 500);
    }
  }, []);

  useEffect(() => {
    const currentUrl = new URL(window.location);
    const params = currentUrl.searchParams;

    if (countryFilter) {
      params.set('country', countryFilter);
    } else {
      params.delete('country');
    }

    window.history.pushState({}, '', currentUrl.toString());
  }, [countryFilter]);

  useEffect(() => {
    if(mode === "explore") {
      setNewPin(null);
    } else {
      setOpenPin(false);
    }
  }, [mode]);
  
  return (
    <PinMapContext.Provider
      value={{
        containerWidth, setContainerWidth,
        map, setMap,
        pinData, setPinData,
        newPin, setNewPin,
        openPin, setOpenPin,
        pinFilter, setPinFilter,
        countryFilter, setCountryFilter,
        showPublicLifeImages, setShowPublicLifeImages,
        visiblePins, setVisiblePins,
        userId,
        mode, setMode
      }}
    >
      {children}
    </PinMapContext.Provider>
  );
}