import React, { useContext, useState } from "react";

import { point } from "@turf/helpers";

import { PinMapContext } from "./PinMapProvider";

import { newQuestions as questions } from "./utils/questions";
import { createPin } from "./utils/api";
import { resizeImage, fireConfetti } from "./utils/helpers";
import { reverseGeocode } from "./utils/mapHelpers";

const NewPinSidebar = () => {
  const { userId, newPin, setNewPin, setOpenPin, setMode, setPinData } =
    useContext(PinMapContext);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [image, setImage] = useState({ file: null, url: null });
  const [email, setEmail] = useState("");
  const [answers, setAnswers] = useState([]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswerClick = (e) => {
    const answerValue = e.target.getAttribute("data-answer");
    const question = currentQuestion.question;

    const existingAnswerIndex = answers.findIndex(
      (answer) => answer.question === question
    );

    if (existingAnswerIndex !== -1) {
      if (currentQuestion.type === "multiple-choice") {
        const existingAnswer = answers[existingAnswerIndex];
        const answerIndex = existingAnswer.answers.indexOf(answerValue);
        if (answerIndex !== -1) {
          const updatedAnswers = [
            ...existingAnswer.answers.slice(0, answerIndex),
            ...existingAnswer.answers.slice(answerIndex + 1),
          ];
          const updatedAnswerObject = {
            ...existingAnswer,
            answers: updatedAnswers,
          };
          setAnswers([
            ...answers.slice(0, existingAnswerIndex),
            updatedAnswerObject,
            ...answers.slice(existingAnswerIndex + 1),
          ]);
        } else {
          const updatedAnswers = [...existingAnswer.answers, answerValue];
          const updatedAnswerObject = {
            ...existingAnswer,
            answers: updatedAnswers,
          };
          setAnswers([
            ...answers.slice(0, existingAnswerIndex),
            updatedAnswerObject,
            ...answers.slice(existingAnswerIndex + 1),
          ]);
        }
      } else {
        const updatedAnswers = answers.map((answer, index) => {
          if (index === existingAnswerIndex) {
            return { ...answer, answer: answerValue };
          }
          return answer;
        });
        setAnswers(updatedAnswers);
      }
    } else {
      if (currentQuestion.type === "multiple-choice") {
        setAnswers([...answers, { question, answers: [answerValue] }]);
      } else {
        setAnswers([...answers, { question, answer: answerValue }]);
      }
    }
  };

  const handleInputChange = (e) => {
    const inputAnswerValue = e.target.value;
    const questionText = currentQuestion.question;

    const existingAnswerIndex = answers.findIndex(
      (answer) => answer.question === questionText
    );

    if (existingAnswerIndex !== -1) {
      const updatedAnswers = answers.map((answer, index) => {
        if (index === existingAnswerIndex) {
          return { ...answer, answer: inputAnswerValue };
        }
        return answer;
      });
      setAnswers(updatedAnswers);
    } else {
      setAnswers([
        ...answers,
        { question: questionText, answer: inputAnswerValue },
      ]);
    }
  };

  const handleImageChange = (e) => {
    let file = e.target.files[0];

    resizeImage(file, 320, 320, (resizedBlob) => {
      const imageUrl = URL.createObjectURL(resizedBlob);

      setImage({ file: file, url: imageUrl });
    });
  };

  const handleSubmitPin = () => {
    reverseGeocode(
      newPin.geometry.coordinates[0],
      newPin.geometry.coordinates[1]
    ).then((location_name) => {
      const description = answers.filter((a) => a.question !== "title");
      const title = answers.find((a) => a.question === "title").answer;

      const newPinData = {
        latitude: newPin.geometry.coordinates[1],
        longitude: newPin.geometry.coordinates[0],
        description: description,
        title: title,
        user_id: userId,
        location_name: location_name,
      };

      if (image.file) {
        newPinData.image = image.file;
        newPinData.is_public_life_photo = true;
        newPinData.email = email;
      }

      createPin(newPinData).then((data) => {
        fireConfetti();

        let needs_confirmation = data.needs_confirmation;
        let newPinData = JSON.parse(data.pin);

        setPinData((pinData) => [...pinData, newPinData]);

        let pin = point([newPinData.longitude, newPinData.latitude], {
          ...newPinData,
          answers: newPinData.description,
          needs_confirmation: needs_confirmation,
        });
        setOpenPin(pin);

        setAnswers([]);
        setCurrentQuestionIndex(0);
        setNewPin(null);
        setMode("explore");
      });
    });
  };

  const isNextButtonDisabled = () => {
    if (currentQuestion.type !== "image") {
      return (
        !answers.find(
          (answer) => answer.question === currentQuestion.question
        ) ||
        answers.find((answer) => answer.question === currentQuestion.question)
          ?.answer === ""
      );
    } else if (currentQuestion.type === "image" && image.url !== null) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return !email || !emailRegex.test(email);
    }
    return false;
  };

  return (
    <div
      id="new-pin-sidebar-container"
      className="right-sidebar-container sidebar-container"
    >
      <div className="close-btn" onClick={() => setNewPin(false)} />

      <div className="d-flex justify-content-between align-items-center flex-column h-100 w-100">
        <div className="w-100">
          <h1 className="mb-3 w-75">Add a favorite place to the map</h1>

          <div className="questions-container">
            {currentQuestion.type === "input" && (
              <>
                {currentQuestion.description && (
                  <p className="my-3">{currentQuestion.description}</p>
                )}

                <label htmlFor="titleInput" className="form-label fw-bold">
                  {currentQuestion.helpText}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="titleInput"
                  placeholder="Enter a title"
                  value={
                    answers.find(
                      (answer) => answer.question === currentQuestion.question
                    )?.answer || ""
                  }
                  onChange={(e) => handleInputChange(e)}
                />
              </>
            )}

            {currentQuestion.type === "image" && !userId && (
              <div>
                {currentQuestion.description && (
                  <p className="my-3" style={{ whiteSpace: "pre-wrap" }}>
                    {currentQuestion.description}
                  </p>
                )}

                <p className="my-3" style={{ whiteSpace: "pre-wrap" }}>
                  You need to be logged in to upload a photo. Please <a href="/users/sign_in">Log in</a> or <a href="/users/sign_up">Sign up</a> to continue.
                  <br />
                  <br />
                  If you do not wish to create a user, or don't have a photo, that's okay too, just skip to the next question.
                </p>
              </div>
            )}

            {currentQuestion.type === "image" && userId !== null && (
              <div className="">
                {currentQuestion.description && (
                  <p className="my-3" style={{ whiteSpace: "pre-wrap" }}>
                    {currentQuestion.description}
                    <br />
                    <br />
                    If you don't have a photo, that's okay too, just skip to the
                    next question.
                  </p>
                )}

                {!image.url && (
                  <label
                    id="image-upload-label"
                    htmlFor="image-upload"
                    className="btn btn-primary"
                  >
                    {currentQuestion.helpText}
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </label>
                )}

                {image.url && (
                  <div className="image-container position-relative mt-3 w-50">
                    <div
                      className="close-btn close-btn-sm"
                      onClick={() => setImage({ file: null, url: null })}
                    />
                    <img src={image.url} className="img-fluid w-100" />
                  </div>
                )}

                {image.file && (
                  <>
                    <p className="my-2 small">
                      When uploading a photo, we ask you for your email, so we
                      can confirm that you are a real person. You will be sent a
                      confirmation email with a link to verify your email
                      address. Your email address will not be shared with
                      anyone.
                    </p>

                    <label htmlFor="emailInput" className="form-label fw-bold">
                      Please enter your email address
                    </label>

                    <input
                      type="email"
                      className="form-control"
                      id="emailInput"
                      placeholder="example@email.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <p className="small mt-2 mb-1 text-muted">
                      By adding a pin and uploading a photo, you agree to our{" "}
                      <a
                        href="/pages/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-muted"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/pages/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-muted"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </>
                )}
              </div>
            )}
            {currentQuestion.type === "multiple-choice" && (
              <>
                <p className="question mb-1">
                  {currentQuestion.question.charAt(0).toUpperCase() +
                    currentQuestion.question.slice(1)}
                </p>
                <p className="question small mb-3">
                  {currentQuestion.helpText}
                </p>
                {currentQuestion.answers.map((answer, index) => {
                  const isSelected = answers
                    .find((a) => a.question === currentQuestion.question)
                    ?.answers.includes(answer);
                  return (
                    <div
                      key={index}
                      className={`question-answer ${
                        isSelected ? "selected" : ""
                      }`}
                    >
                      <div
                        className="answer-bubble"
                        data-answer={answer}
                        onClick={handleAnswerClick}
                      ></div>
                      <div
                        className="answer-text"
                        data-answer={answer}
                        onClick={handleAnswerClick}
                      >
                        {answer}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>

        <div
          id="new-pin-detail-navigation"
          className="d-flex justify-content-between align-items-top w-100 py-3"
        >
          {currentQuestionIndex !== 0 ? (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() =>
                setCurrentQuestionIndex(
                  currentQuestionIndex === 0
                    ? questions.length - 1
                    : currentQuestionIndex - 1
                )
              }
            >
              Previous
            </button>
          ) : (
            <div></div>
          )}
          {currentQuestionIndex !== questions.length - 1 && (
            <button
              className="btn btn-sm btn-outline-primary"
              disabled={isNextButtonDisabled()}
              onClick={() =>
                setCurrentQuestionIndex(
                  (currentQuestion) => currentQuestion + 1
                )
              }
            >
              Next
            </button>
          )}

          {currentQuestionIndex === questions.length - 1 && (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleSubmitPin()}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <div id="progress-container" className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={(
            (currentQuestionIndex / (questions.length - 1)) *
            100
          ).toString()}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${(
              (currentQuestionIndex / (questions.length - 1)) *
              100
            ).toString()}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default NewPinSidebar;
