export const filterOptions = [
  {
    text: 'That are good for relaxing',
    answer: ['It is a good place to relax']
  },
  {
    text: 'With a fun atmosphere',
    answer: ['It has a fun atmosphere']
  },
  {
    text: 'That welcomes babies and toddlers',
    answer: [
      'I see a lot of babies and toddlers here',
      'I bring my own child or children here',
      'I have friends/family who bring their children here',
      'With children'
    ]
  },
  {
    text: 'That feel safe',
    answer: ['It feels safe', 'It has safe and inviting areas to play']
  },
  {
    text: 'With nature',
    answer: ['There is nature']
  },
  {
    text: 'Where you can meet new people',
    answer: ['It is a good place to meet new people']
  },
  {
    text: 'With interesting architecture',
    answer: ['There is interesting architecture']
  },
  {
    text: 'With opportunities to play or do exercise',
    answer: [
      'It has safe and inviting areas to play',
      'There are plenty of activity facilities and opportunities for exercise'
    ]
  }
];
