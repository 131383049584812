export const smartFlyTo = (
  map,
  containerWidth,
  targetCenter,
  targetZoom,
  intermediateZoom = 6,
  options = {}
) => {
  const {
    padding = containerWidth < 1000
      ? { right: 300, left: 5, top: 5, bottom: 5 }
      : { right: 5, left: 5, top: 5, bottom: 5 },
    speedFast = 0.8,
    speedSlow = 0.7,
    curve = 1.42,
    duration = 1000,
  } = options;

  const currentZoom = map.getZoom();

  if (currentZoom < intermediateZoom) {
    map.flyTo({
      center: targetCenter,
      zoom: intermediateZoom,
      speed: speedFast,
      curve: curve,
    });

    setTimeout(() => {
      map.flyTo({
        center: targetCenter,
        zoom: targetZoom,
        padding: padding,
        speed: speedSlow,
        curve: curve,
      });
    }, duration);
  } else {
    map.flyTo({
      center: targetCenter,
      zoom: targetZoom,
      padding: padding,
      speed: speedSlow,
      curve: curve,
    });
  }
};

export const reverseGeocode = (longitude, latitude) => {
  const mapboxAccessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

  return new Promise((resolve, reject) => {
    let geoCodeURL = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&access_token=${mapboxAccessToken}&permanent=true&types=place,country&language=en`;

    fetch(geoCodeURL)
      .then((response) => response.json())
      .then((data) => {
        if (!data.features || data.features.length === 0) {
          throw new Error("No features found");
        }

        const context = data.features[0].properties.context;
        const place = context.place ? context.place.name : null;
        const country = context.country ? context.country.name : null;

        if (place || country) {
          let string = [place, country].filter(Boolean).join(", ");
          resolve(string);
        } else {
          throw new Error("Place or country not found");
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};
