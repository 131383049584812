import React, { useContext, useEffect, useRef } from "react";

import { PinMapContext } from "./PinMapProvider";
import Map from "./Map";
import FilterSidebar from "./FilterSidebar";
import InsightsSidebar from "./InsightsSidebar";
import PinDetailsSidebar from "./PinDetailsSidebar";
import NewPinSidebar from "./NewPinSidebar";
import ModeSelector from "./ModeSelector";

import { getPins } from "./utils/api";

const testMode = import.meta.env.VITE_TEST_MODE;

const PinMapInterface = () => {
  const { setPinData, newPin, openPin, setContainerWidth } = useContext(PinMapContext);
  const containerRef = useRef(null);


  const handleResize = () => {
    setContainerWidth(containerRef.current?.clientWidth);
  };

  useEffect(() => {
    getPins().then((pins) => {
      setPinData(pins);
    });

    handleResize(); // 
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  return (
    <div id="interface-container" ref={containerRef}>
      <FilterSidebar />
      <Map />

      {newPin && <NewPinSidebar />}
      {openPin && <PinDetailsSidebar />}
      {!newPin && !openPin && <InsightsSidebar />}

      <ModeSelector />

      {testMode === "true" && (
        <div id="test-mode-container">
          <p>THE APP IS IN TEST MODE</p>
          <p>NO DATA WILL BE SAVED</p>
        </div>
      )}
    </div>
  );
};

export default PinMapInterface;
