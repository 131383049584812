import React, { useContext, useState } from 'react';

import PinMapProvider from './PinMapProvider';
import PinMapInterface from './PinMapInterface';

const PinMap = (props) => {

  return (
    <PinMapProvider props={props}>
      <PinMapInterface />
    </PinMapProvider>
  );
}

export default PinMap;