import React, { useContext, useState, useEffect } from "react";

import { point } from "@turf/helpers";

import { PinMapContext } from "./PinMapProvider";

import { getPinImage } from "./utils/api";
import { extractInsights, questionMapping } from "./utils/insights";
import { getRandomItems } from "./utils/helpers";

import circleInfo from "../../../../assets/images/circle-info.svg";

const InsightsSidebar = () => {
  const { visiblePins, setOpenPin } = useContext(PinMapContext);

  const [publicLifePhotosForCarousel, setPublicLifePhotosForCarousel] =
    useState([]);
  const [publicLifePhotosLoaded, setPublicLifePhotosLoaded] = useState(false);

  const [showInsightsMobile, setShowInsightsMobile] = useState(false);

  const favoritePlaces = visiblePins;
  const publicLifePhotos = visiblePins.filter(
    (pin) => pin.is_public_life_photo && pin.confirmed_at
  );
  const insights = extractInsights(visiblePins);

  useEffect(() => {
    setPublicLifePhotosLoaded(false);
    let pins = getRandomItems(publicLifePhotos, 5);
    Promise.all(
      pins.map((p) =>
        getPinImage(p.id).then((imageUrl) => ({
          ...p,
          imageUrl,
        }))
      )
    )
      .then((pinsWithImages) => {
        setPublicLifePhotosForCarousel(pinsWithImages);
      })
      .catch((error) => console.error("Failed to load images", error));
  }, [visiblePins]);

  useEffect(() => {
    if (publicLifePhotosForCarousel.length === 0) return;

    const images = document.querySelectorAll(
      "#public-life-photos-container img"
    );

    let loadedCount = 0;
    let totalImages = images.length;

    const checkIfAllImagesLoaded = () => {
      if (loadedCount === totalImages) {
        setPublicLifePhotosLoaded(true);
      }
    };

    images.forEach((img) => {
      if (img.complete) {
        loadedCount += 1;
        checkIfAllImagesLoaded();
      } else {
        img.onload = () => {
          loadedCount += 1;
          checkIfAllImagesLoaded();
        };

        img.onerror = (e) => {
          checkIfAllImagesLoaded();
        };
      }
    });
  }, [publicLifePhotosForCarousel]);

  const handleClickPhoto = (photo) => {
    let pin = point([photo.longitude, photo.latitude], {
      ...photo,
      answers: photo.description,
    });

    setOpenPin(pin);
  };

  return (
    <>
      <div
        id="insights-sidebar-btn"
        className="p-2"
        onClick={() => setShowInsightsMobile(true)}
      >
        <img src={circleInfo} />
      </div>

      <div
        id="insights-sidebar-container"
        className={
          showInsightsMobile
            ? "right-sidebar-container sidebar-container show-mobile"
            : "right-sidebar-container sidebar-container"
        }
      >
        <div
          className="close-btn"
          onClick={() => setShowInsightsMobile(false)}
        />

        <div className="d-flex justify-content-between flex-column h-100">
          <div>
            <div id="stats-container">
              <p className="large my-0">
                You are currently viewing{" "}
                <span className="fw-bold">
                  {favoritePlaces.length} favorite places
                </span>{" "}
                and{" "}
                <span className="fw-bold">
                  {publicLifePhotos.length} images
                </span>
              </p>
            </div>

            <div id="insights-container">
              {Object.entries(insights).map(([question, answers]) => (
                <div key={question} className="mb-4">
                  <p className="mb-1 mt-3 fw-bold">
                    {questionMapping[question] || question}
                  </p>
                  <div className="insights-answers-container d-flex justify-content-between w-100">
                  {answers.slice(0, 2).map((answer, index) => (
                    <div className="insights-answer-box" key={index}>
                      <p className="insight-percentage x-large fw-bold mt-0 mb-1">{answer.percentage}</p>
                      <p className="insight-answer x-small my-0">{answer.text}</p>
                    </div>
                  ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            id="public-life-photos-container"
            className={publicLifePhotosLoaded ? "mt-3" : "mt-3 loading"}
          >
            {publicLifePhotosForCarousel.map((image) => {
              return (
                <div
                  key={image.id}
                  className="public-life-photo w-100"
                  onClick={() => handleClickPhoto(image)}
                >
                  <img src={image.imageUrl} className="w-100" />
                </div>
              );
            })}

            {publicLifePhotosLoaded && publicLifePhotos.length > 5 && (
              <div className="public-life-photo public-life-photo-placeholder w-100">
                <div>
                  <span className="d-block">+</span>
                  {publicLifePhotos.length - 5} more
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightsSidebar;
